import { toZonedTime } from "date-fns-tz";
import { format } from "date-fns";

export const formatToWat = (date: string) => {
  const watTimeZone = "Africa/Lagos";

  // Define the time zone

  // Example dates
  const modifiedDate = new Date(date);

  // Convert the input date to the WAT timezone
  const watDate = toZonedTime(modifiedDate, watTimeZone);

  // Format the date to the desired format
  const formattedDate = format(watDate, "dd/MM/yyyy HH:mm:ss");

  return formattedDate; // Output: 24/12/2024 15:41:24 (example)
};
