import { TableTab } from "components";
import ContentLayout from "layouts/ContentLayout";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formatMoney } from "utils/formatMoney";
import { Link, useLocation } from "react-router-dom";
import { MainItemType } from "./Orders";
import OrderUserIcon from "../../assets/svg/orderIcon.svg";
import OrderTruckIcon from "../../assets/svg/orderDelivery.svg";

const header = [
  { key: 1, item: "Product name" },
  { key: 2, item: "Quantity" },
  { key: 3, item: "Unit Price" },
  { key: 4, item: "Amount" },
  { key: 5, item: "Promo Type" },
];

type stateType = {
  item: MainItemType;
  status: string;
};

const OrderDetails = () => {
  const location = useLocation();
  const [item, setItem] = useState<MainItemType>();
  const [date, setDate] = useState<Date>();
  const [csvData, setCsvData] = useState<any>();

  useEffect(() => {
    const { item: data, status } = location.state as stateType;
    setDate(new Date(data.createdAt));
    setItem(data);

    const csvData = data.products.map((item: { [k: string]: any }): any => {
      const roleDescription =
        Number(item?.description?.rolls) > 0 &&
        `${item?.description?.rolls} rolls`;
      const packDecription =
        Number(item?.description?.packs) > 0 &&
        `${item?.description?.packs} packs`;

      const description = roleDescription || packDecription;

      const rollUnitPrice =
        Number(item?.description?.rolls) > 0 &&
        (item.amount / item.quantity) * 10;
      const packUnitPrice =
        Number(item?.description?.packs) > 0 && item.amount / item.quantity;

      const unitPrice = rollUnitPrice || packUnitPrice;

      return {
        product: item.productName,
        quantity: description,
        unitPrice,
        amount: item.amount,
        promoType: item.tag || "NIL",
      };
    });
    setCsvData(csvData);
  }, []);

  return (
    <ContentLayout
      titleText={item?.orderId}
      subText="Details of order"
      orderStatus={item?.deliveryStatus}
    >
      <DetailsWrap>
        <div className="boxes-wrap">
          <div className="detail-box">
            <div className="inner-box-wrap">
              <div className="box-details-header">
                <span>
                  <img src={OrderUserIcon} alt="" />
                </span>
                <span>Sale Rep & Depot details</span>
              </div>
              <p className="name">
                <span>Sales rep details:</span>
                <span>{`${item?.customerInfo?.repname}, ${item?.customerInfo?.urno}, ${item?.repsInfo ? item?.repsInfo.repPhoneNo : "Nil"}`}</span>
              </p>
              <p className="id">
                <span>TM Details:</span>
                <span>{item?.outletID}</span>
              </p>
              <p className="phone">
                <span>Region:</span>
                <span>{item?.customerInfo?.region}</span>
              </p>
              <p className="address">
                <span>Depot:</span>
                <span>{item?.customerInfo?.depot}</span>
              </p>
            </div>
          </div>
          <div className="detail-box">
            <div className="inner-box-wrap">
              <div className="box-details-header">
                <span>
                  <img src={OrderTruckIcon} alt="" />
                </span>
                <span>Delivery & Verification Details</span>
              </div>
              <p className="name">
                <span>Transaction ID:</span>
                <span>{item?.transactionId}</span>
              </p>
              <p className="id">
                <span>Transaction Token:</span>
                <span>{item?.token}</span>
              </p>
              <p className="phone">
                <span>Delivery Address:</span>
                <span>{item?.customerInfo.outletaddress}</span>
              </p>
              <p className="address">
                <span>Phone Number:</span>
                <span>
                  {item?.outletInfo ? item?.outletInfo.outletPhoneNo : "Nil"}
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="data-container">
          <TableTab
            data={csvData}
            file="order-details.csv"
            text="Items and orders details"
            canFilter={false}
          />

          <div className="table-container">
            <table>
              <thead>
                <tr>
                  {header.map((header) => (
                    <th key={header.key}>{header.item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {item?.products.map((product, index) => {
                  let matchingProduct = [];
                  let deliveredQuantity;

                  if (item?.delivery) {
                    matchingProduct = item?.delivery.items.find(
                      (data: any) => product.productCode === data.code
                    );
                    console.log("//", matchingProduct);

                    deliveredQuantity =
                      matchingProduct == undefined
                        ? "-"
                        : matchingProduct.quantity;
                  } else {
                    deliveredQuantity = "-";
                  }

                  return (
                    <tr key={index}>
                      <td className="prod-cnt">
                        <div className="prod-image">
                          <img src={product.image} alt="" />
                        </div>
                        {product.productName}
                      </td>
                      <td>
                        {Number(product?.description?.rolls) > 0 &&
                          `${product?.description?.rolls} rolls`}{" "}
                        {Number(product?.description?.packs) > 0 &&
                          `${product?.description?.packs} packs`}
                      </td>
                      <td>
                        {Number(product?.description?.rolls) > 0 &&
                          formatMoney((product.amount / product.quantity) * 10)}
                        {Number(product?.description?.packs) > 0 &&
                          formatMoney(product.amount / product.quantity)}
                      </td>
                      <td>{formatMoney(product.amount)}</td>

                      <td>{product.tag || "NIL"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="total">
          <p>Total</p>

          <p className="cost">
            <span>Total order cost: </span>
            <span>{item?.totalAmount && formatMoney(item?.totalAmount)}</span>
          </p>
        </div>
        <div className="history">
          <Link to={`/dashboard/outlet-management/${item?.outletID}/history`}>
            <button className="btn btn-default pointer">
              View outlet history
            </button>
          </Link>
        </div>
      </DetailsWrap>
    </ContentLayout>
  );
};

const DetailsWrap = styled.div`
  font-size: 13px;
  line-height: 1.3;

  .table-container {
    height: 100%;
    min-height: 200px;

    table {
      border-bottom: 1px solid #d9d8da;
    }
  }

  .boxes-wrap {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

  .detail-box {
    width: 400px;
    height: 200px;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 15px;
    animation: fadeIn ease-in;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opaacity: 1;
      }
    }

    :not(:first-child) {
      margin-left: 16px;
    }
    :nth-child(1) {
      animation-duration: 1s;
    }
    :nth-child(2) {
      animation-duration: 2s;
    }
    :nth-child(3) {
      animation-duration: 2.5s;
    }
  }

  .inner-box-wrap p {
    margin-top: 12px;

    span:first-child {
      font-weight: 600;
    }
    span:last-child {
      margin-left: 6px;
    }
  }

  .box-details-header {
    display: flex;
    place-items: center;
    span:first-child {
      // background-color: #022f1a;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: grid;
      place-items: center;
    }
    span:last-child {
      margin-left: 8px;
      font-size: 18px;
    }
  }

  .history {
    text-align: center;
    margin-block: 5em;
    button {
      width: 245px;
    }
  }

  .prod-cnt {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .prod-image {
    width: 20px;
    height: 20px;
  }

  .prod-image img {
    width: 100%;
  }

  .total {
    display: flex;
    justify-content: space-between;
    padding: 0 70px 0 20px;

    p {
      font-weight: 600;
      font-size: 18px;
      span:last-child {
        font-weight: 300;
        margin-left: 4px;
      }
    }
    .cost {
      border-bottom: 1px solid #d9d8da;
      padding-block: 1em;
    }
  }
`;

export default OrderDetails;
